import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/challenge-4/",
  "date": "2020-05-29T00:00:00.000Z",
  "title": "Goodbye boring forms!",
  "blurb": "My challenges and victories whilst attempting challenge #4",
  "tags": ["formik", "react", "components"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hola todos del mundo,`}</p>
    <p>{`This week the challenge was to import a third-party React library into my blog. Unlike last week, I felt this was more in my ballpark having previous played around with React and JSX. The challenge suggested Formik, a cool library for building forms. I was quite open to learning this new little library as learning all this new Gatsby stuff, even though it's based on React, is quite foreign. This week, I seeked some familiarity.`}</p>
    <p>{`Those of you familiar with React will possibly find this task easier than last week so if you wish to add a little challenge I'd recommend implementing a backend, or a psuedo-backend. Something that processes off your Gatsby site. Yes, this will require set up of third-party features or your own architecture (server build enthusiasts).`}</p>
    <h2>{`Hello, `}<em parentName="h2">{`Formik`}</em></h2>
    <p>{`To get started, work through `}<a parentName="p" {...{
        "href": "https://jaredpalmer.com/formik/docs/tutorial"
      }}>{`Formik's in browser tutorial`}</a>{`. It's pretty straight forward so long as you are familiar with the prerequisites: "familiarity with HTML, CSS, modern JavaScript, and React (and React Hooks)". But not everyone is as I soon discover. No fret.`}</p>
    <p>{`Love their tagline: "Build forms in React, without the tears".`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      